import React, {useState} from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import classNames from "classnames"
import PropTypes from "prop-types"
import Button from "Accessories/Button/Button"
import "./NewsletterForm.scss"

const NewsletterForm = ({
  successMsg,
  classes,
  errorMsg
}) => {

  const [email, setEmail] = useState("")
  const [hasClicked, setClicked] = useState(false)
  const [hasSubscribed, setSubscribed] = useState(false)
  const [hasSubscribedError, setSubscribedError] = useState(false)

  // Mailchimp Submithandler
  const handleSubmit = async (e) => {
    // eslint-disable-next-line
    e.preventDefault()

    setClicked(true)

    const result = await addToMailchimp(email)
    if (result.result !== "error") {
      setSubscribed(true)
    } else {
      setSubscribedError(true)
      setClicked(false)
    }
  }

  const handleChange = (e)  => {
    setEmail(e.target.value)
  }

  return (
    <div className={classNames("footer-newsletter-container", classes)}>
      <form className={classNames("newsletter-form", hasClicked ? "is-disabled" : "")} onSubmit={(e) => handleSubmit(e)}>
        <input type="email" placeholder="Sign up for our Newsletter" value={email} onChange={(e) => handleChange(e)} />
        <Button
          className="btn-newsletter"
          realButton
          text="Send"
        />
      </form>
      {
        hasSubscribed && email !== "" && <span className="newsletter-subsciption-confirmation">{successMsg}</span>
      }
      {
        hasSubscribedError && !hasSubscribed && <span className="newsletter-subsciption-error">{errorMsg}</span>
      }
    </div>
  )
}

NewsletterForm.propTypes = {
  successMsg: PropTypes.string,
  errorMsg: PropTypes.string,
  classes: PropTypes.string,
}

NewsletterForm.defaultProps = {
  successMsg: "Successfully subscribed :)",
  errorMsg: "Something went wrong :(",
  classes: ""
}

export default NewsletterForm
