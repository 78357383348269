import React, { useEffect }  from "react";
import Helmet from "react-helmet";
import BackgroundGraphics from "Accessories/BackgroundGraphics/BackgroundGraphics";
import TopNavigation from "Layout/Navigation/Navigation";
import config from "../../../data/SiteConfig";
import "./style.scss";
import Footer from "./Footer/Footer";

const MainLayout = (props) => {

  if (typeof window === 'undefined') {
    global.window = {}
  }

  const { children, location } = props;

  const titleize = (slug) => {
    const words = slug.split("-");
      return words.map((word) => {
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
      }).join(' ');
  }

  const getLocalTitle = () => {
    const pathPrefix = config.pathPrefix ? config.pathPrefix : "/";
    const currentPath = location.pathname
    .replace(pathPrefix, "")
    .replace("/", "")
    const title = currentPath === "" ? "Home" : titleize(currentPath)
    return title;
  }

  const handleTouch = () => {
    document.body.classList.add("is-touch-device");
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener("touchstart", handleTouch);
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (typeof window !== 'undefined') {
        window.removeEventListener("touchstart", handleTouch);
      }
    };
  });

  return (
    <React.Fragment>
      <Helmet
        title={`${getLocalTitle()} | ${config.siteTitle}`}
        meta={[
          {
            name: 'description',
            content: config.siteDescription,
          },
          {
            name: 'image',
            content: config.siteSeoDefaultImage,
          }
        ]}
      />
      <BackgroundGraphics>
        <TopNavigation location={location} />
        {children}
        <Footer location={location} />
      </BackgroundGraphics>
    </React.Fragment>
  );
}

export default MainLayout
