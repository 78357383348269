import React from 'react';
import { Link } from "gatsby";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./FooterNav.scss";


const FooterNav = (props) => {

  const { navItems, classes, copyright, privacy } = props

  return (
    <nav
      className={classNames("footer-bottom-menu", classes)}
    >
      { copyright && (
        <span className="copyright-text text-xs">{ copyright }</span>
      )}
      <ul className="d-flex">
        {navItems.map((menuItem) => (
          <li key={menuItem.object_slug} className="nav-item footer-nav-item">
            <Link
              to={`/${menuItem.object_slug}/`}
              className="nav-item-link text-link text-xs"
            >
              {menuItem.title}
            </Link>
          </li>
        ))}
        {privacy &&
          <li><a className="nav-item-link text-link text-xs" href={privacy}>Privacy</a></li>
        }
      </ul>
    </nav>
  )
}

FooterNav.propTypes = {
  navItems: PropTypes.instanceOf(Array).isRequired,
  classes: PropTypes.string,
};

FooterNav.defaultProps = {
  classes: ""
};

export default FooterNav