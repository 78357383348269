import React, {useEffect} from "react"
import {useStaticQuery, graphql} from "gatsby"
import "./Footer.scss"
import NewsletterForm from "Accessories/NewsletterForm/NewsletterForm"
import SocialNav from "Accessories/SocialNav/SocialNav"
import FooterNav from "Layout/Footer/FooterNav/FooterNav"

const Footer = ({location}) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      menus: allWordpressWpApiMenusMenusItems {
        edges {
          node {
            name
            items {
              title
              object_slug
              url
            }
          }
        }
      }
      optionsPage: allWordpressAcfOptions {
        edges {
          node {
            global_data {
              footer_info {
                address
                contact
                privacy
              }
            }
          }
        }
      }
    }
  `)

  const footerMenu = data.menus.edges.filter(menu => menu.node.name === "Footer Menu")[0].node.items
  const footerSocialMenu = data.menus.edges.filter(menu => menu.node.name === "Social Menu")[0].node.items
  const year = new Date().getFullYear()
  const footerInfo = data.optionsPage.edges[0].node.global_data.footer_info
  const newsletterId = "signup"

  useEffect(() => {
    if(location && location.hash && location.hash === `#${newsletterId}`) {
      if (typeof window !== "undefined") {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth"
        })
      }
    }
  }, [])

  return (
    <footer className="footer-section container-fluid pt-7 pb-1">
      <div className="container footer-main">
        <div className="row">
          <div className="footer-title-block mr-auto text-md col-md-4">
            (The Center
            <br />
            for Philosophical
            <br />
            Technologies)
          </div>
          <div className="footer-contact-block col-md-4 mr-auto text-md">
            (Address)
            <br />
            <div dangerouslySetInnerHTML={{ __html: footerInfo.address }} />
          </div>
          <div className="footer-contact-block col-md-4 mr-auto text-md mt-1 mt-lg-0">
            (Contact)
            <div dangerouslySetInnerHTML={{ __html: footerInfo.contact }} />
          </div>
          <div
            className="column-newsletter col-12 col-md-6 col-lg-4 offset-md-6 offset-lg-8 footer-column my-2"
            id={newsletterId}
          >
            <NewsletterForm />
          </div>
        </div>
        <div className="row footer-bottom justify-content-between pt-1 pt-lg-2">
          <FooterNav navItems={footerMenu} privacy={footerInfo.privacy} copyright={`${year} CPT`} classes="d-flex col-12 col-lg-4 order-2 order-lg-1" />
          <SocialNav navItems={footerSocialMenu} classes="ml-auto col-lg-4 order-1 order-lg-2" />
        </div>
      </div>
    </footer>
  )
}

export default Footer
