import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import { TweenMax, Expo } from "gsap";
import "./BackgroundGraphics.scss";

const BackgroundGraphics = ({children}) => {
  const [index, setIndex] = useState(0);

  function getRandomIntInclusive(min, max) {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min); 
  }

  useEffect(() => {
    const ball = document.querySelector('.cursor', '.btn');
    const hoverables = document.querySelectorAll('a, .btn, .Collapsible');
  
    // Move the cursor
    function onMouseMove(e) {
      TweenMax.to(ball, 0, {
        x: e.pageX - 12.5,
        y: e.pageY - 12.5
      })
    }
  
    // Hover an element
    function onMouseHover() {
      TweenMax.to(ball, 0.4, {
        ease: Expo.easeOut,
        width: "62.5px",
      })
    }
    function onMouseHoverOut() {
      TweenMax.to(ball, 0.4, {
        ease: Expo.easeOut,
        width: "25px"
      })
    }
    // Listeners
    document.body.addEventListener('mousemove', onMouseMove);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < hoverables.length; i++) {
      hoverables[i].addEventListener('mouseenter', onMouseHover);
      hoverables[i].addEventListener('mouseleave', onMouseHoverOut);
    }
  
    TweenMax.to(ball, 0, {
      ease: Expo.easeOut,
      width: "25px"
    })

    setIndex(getRandomIntInclusive(1, 4));
  });

  return (
    <div id="content-wrapper" className={classNames(`content-wrapper background-graphics bg-${index}`)}>
      {children}
      <div className="cursor" />
    </div>
  )
}

export default BackgroundGraphics
