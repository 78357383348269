import React from 'react';
import classNames from "classnames";
import PropTypes from "prop-types";
import "./SocialNav.scss";

const SocialNav = (props) => {

  const {navItems, classes, ...otherProps} = props;

  return (
    <nav className={classNames("footer-social-menu", classes)} {...otherProps}>
      <ul className="d-flex justify-content-lg-between">
        {navItems.map(menuItem => (
          <li key={menuItem.object_slug} className="text-xs">
            <a
              href={menuItem.url}
              rel="noopener noreferrer"
              target="_blank"
              className="menu-item text-link"
            >
              {menuItem.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )
}

SocialNav.propTypes = {
  navItems: PropTypes.instanceOf(Array).isRequired,
  classes: PropTypes.string,
};

SocialNav.defaultProps = {
  classes: "",
};

export default SocialNav