import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Button.scss";

const Button = (props) => {

  const {
    externalLink,
    className,
    realButton,
    onClick,
    text,
  } = props;

  return (
    <React.Fragment>
      { externalLink && (
        <a
          href={externalLink}
          className={classNames(className, "btn bracket-text")}
          rel="noopener noreferrer"
          target="_blank"
        >
          {text && text}
        </a>
        )
      }
      {
        realButton && (
          <button
            type="submit"
            className={classNames(className, "btn bracket-text")}
            tabIndex={0}
          >
            {text && text}
          </button>
        )
      }
      {
        !externalLink && !realButton && (
          <div
            onClick={onClick}
            onKeyDown={onClick}
            className={classNames(className, "btn bracket-text")}
            role="button"
            tabIndex={0}
          >
            {text && text}
          </div>
        )
      }
    </React.Fragment>
  );
}

Button.propTypes = {
  externalLink: PropTypes.string,
  className: PropTypes.string
};

Button.defaultProps = {
  externalLink: "",
  className: ""
};

export default Button
